import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import ContactUsSection from "../components/contactUs";
import DonateNow from "../components/donateNow";
import DonationInfoSection from "../components/donationInfo";
import FAQ from "../components/faq";
import FundsCollectionStatusSection from "../components/fundsCollectionStatus";
import HeroSection from "../components/heroSection";
import { Layout } from "../components/layout";
import LiveEdit from "../components/liveEdit";
import OurMissionSection from "../components/ourMission";
import TestimonialsSection from "../components/testimonials";
import NgoSection from "../components/ngoSection";
import EventsSection from "../components/events";
import MediaCoverageSection from "../components/mediaCoverage";
import ImplementationEcosystem from "../components/implementationEcosystem";
import LeaderBoardSection from "../components/leaderboardSection";
import OrganisersSection from "../components/organisers";
import ChildCampaignList from "../components/childCampaignList";
import IndiaMap from "../components/indiaMap";

function DigitalDaanaCampaignDetails(props) {
  const node = props.data.contentfulCampaign;
  const campaignSlug = `/campaign/${node.slug}`;
  const campaignAbsoluteURL = `${process.env.GATSBY_PUBLIC_URL}${campaignSlug}`;
  const campaignBannerImage = `https:${node.banner[0].image.file.url}`;

  return (
    <>
      <Helmet>
        <meta
          name="og:image"
          property="og:image"
          content={`${campaignBannerImage}?w=430&h=495`}
        />
        <title>{node.title} | Metta Social</title>
        <meta name="twitter:title" content={node.title} />
        <meta name="og:title" property="og:title" content={node.title} />
        <meta property="og:type" name="og:type" content="website" />
        <meta name="description" content={node.seoDescription.seoDescription} />
        <meta
          property="og:description"
          name="og:description"
          content={node.seoDescription.seoDescription}
        />
        <meta
          name="twitter:description"
          content={node.seoDescription.seoDescription}
        />
        <meta property="og:url" name="og:url" content={campaignAbsoluteURL} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@metta_social" />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=128&h=128`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=192&h=192`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=200&h=200`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=512&h=512`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=800&h=800`}
        />
        <meta
          name="image"
          property="og:image"
          content={`${campaignBannerImage}?w=1200&h=600`}
        />
        <meta
          name="og:image:alt"
          property="og:image:alt"
          content={node.title}
        />
        <meta
          name="twitter:image"
          content={`${campaignBannerImage}?w=128&h=128`}
        />
        {!process.env.GATSBY_ENV && (
          <script
            type="text/javascript"
            src="https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-609004268a9d389e"
            async
            defer
          ></script>
        )}
      </Helmet>

      <Layout title={node.title}>
        <div className="position-relative row col-12 mx-0 px-0">
          <HeroSection banner={node.banner} ngo={node.ngo}></HeroSection>
          <DonateNow campaign={node} tagLine={node.tagLine} />
        </div>
        <OrganisersSection />
        <FundsCollectionStatusSection campaign={node} />
        <OurMissionSection
          mission={node.mission}
          fundUsage={node.fundUsage}
        ></OurMissionSection>
        <TestimonialsSection
          testimonials={node.testimonials}
        ></TestimonialsSection>

        <div className="col-12 row mx-0 my-4 py-4 align-items-center">
          <span className="pill-label pill-bg-gradient">
            BENEFICIARY LOCATIONS
          </span>
          <h1 className="section-title text-black px-lg-3">
            The Digital Kits will be distributed in the following states.
          </h1>
          <IndiaMap />
          <DonationInfoSection
            className="col-12 col-lg-6 bg-white mt-5 mt-lg-0"
            campaign={node}
            banner={node.banner}
            type="subSection"
          />
        </div>

        <EventsSection event={node.event} />
        <MediaCoverageSection news={node.news} />
        <ImplementationEcosystem />
        <LeaderBoardSection campaign={node} />
        <ChildCampaignList
          data={props.data.allContentfulChildCampaign}
          contentfulId={node.contentful_id}
          slug={node.slug}
          campaignTitle={node.title}
          campaignGoal={node.goal}
          campaignGoalCurrency={node.goalCurrency}
        />

        <FAQ faqcontent={node.faQs}></FAQ>
        <ContactUsSection></ContactUsSection>
        <NgoSection />
      </Layout>
      {process.env.GATSBY_ENV && <LiveEdit />}
    </>
  );
}
export default DigitalDaanaCampaignDetails;
export const query = graphql`
  query($regex: String!, $id: String!) {
    allContentfulChildCampaign(
      filter: {
        childSlug: { regex: $regex }
        parentCampaign: { id: { eq: $id } }
      }
      sort: { order: DESC, fields: goal }
    ) {
      edges {
        node {
          childSlug
          id
          contentful_id
          creatorName
          appeal {
            appeal
          }
          parentCampaign {
            id
            slug
            goalCurrency
          }
        }
      }
    }
    contentfulCampaign(id: { eq: $id }) {
      id
      contentful_id
      slug
      tagLine
      news {
        newsVideoUrl
        category
        newsSlug
        subtitle
        title
        newsDate(formatString: "DD/MMM")
        newsImage {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
            sizes
          }
          description
          title
          file {
            url
          }
        }
        sequenceNumber
      }
      event {
        eventSlug
        eventTitle
        subtitle
        sequenceNumber
        eventDate(formatString: "DD/MMM")
        eventImage {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
          }
          description
          title
        }
      }
      fundUsage {
        buttonText
        title
        contents {
          raw
        }
      }
      ngo {
        ngoName
        rzpKeyId
        accountId
        rzpKeyEncryptedSecret {
          rzpKeyEncryptedSecret
        }
        intRzpKeyId
        intRzpKeyEncryptedSecret {
          intRzpKeyEncryptedSecret
        }
        logo {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
            fileName
          }
          title
        }
      }
      seoDescription {
        seoDescription
      }
      title
      goal
      goalCurrency
      banner {
        buttonLink
        buttontitle
        description
        sequenceno
        title
        image {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
          }
        }
      }
      mission {
        title
        projectReport {
          file {
            url
          }
        }
        description {
          raw
        }
        missionImages {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            fileName
            url
          }
          title
        }
        missionYoutubeLinks
      }
      testimonials {
        sequenceno
        testimonialBy
        designation
        description
        starrating
        image {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
          }
        }
      }
      faQs {
        answer {
          raw
        }
        question
        sequenceno
      }
    }
  }
`;
